import React, { Component } from "react";
import "../assets/css/preloader.css";
import { Helmet } from "react-helmet";

export class Preloader extends Component {
  render() {
    return (
      // Start: Preloader
      <section id="preloader-section">
        <Helmet>
          <title>Welcome To My Website</title>
        </Helmet>
        <div id="preloader">
          <div id="ctn-preloader" className="ctn-preloader">
            <div className="animation-preloader">
              {/* Spinner  */}
              <div className="spinner"></div>
              {/* Start: Text Loading  */}
              <div className="txt-loading">
                <span data-text-preloader="L" className="letters-loading">
                  L
                </span>
                <span data-text-preloader="O" className="letters-loading">
                  O
                </span>
                <span data-text-preloader="A" className="letters-loading">
                  A
                </span>
                <span data-text-preloader="D" className="letters-loading">
                  D
                </span>
                <span data-text-preloader="I" className="letters-loading">
                  I
                </span>
                <span data-text-preloader="N" className="letters-loading">
                  N
                </span>
                <span data-text-preloader="G" className="letters-loading">
                  G
                </span>
              </div>
              {/* End: Text Loading  */}
            </div>

            {/* Start: Preloader sides - Model 1  */}
            <div className="loader-section section-left"></div>
            <div className="loader-section section-right"></div>
            {/* End: Preloader sides - Model 1 */}
          </div>
        </div>
      </section>
      //  End: Preloader
    );
  }
}

export default Preloader;
