import React, { Component } from "react";
import { PortfolioList } from "../database/portfolio";
export class Portfolio extends Component {
  render() {
    return (
      <section id="portfolio" className="portfolio section-bg">
        <div className="container">
          <div className="section-title">
            <h2>Portfolio</h2>
          </div>

          {/* <div className="row" data-aos="fade-up">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-filter">
                <li data-filter="*" className="filter-active">
                  All
                </li>
                {PortfolioList.FilterData.map((filters) => {
                  return (
                    <li data-filter={filters.filter} key={filters.id}>
                      {filters.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div> */}

          <div
            className="row portfolio-container"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            {PortfolioList.DataList.map((datalist) => {
              return (
                <div
                  className={
                    "  col-lg-4 col-md-6 portfolio-item  " + datalist.category
                  }
                  key={datalist.id}
                >
                  <div className="portfolio-wrap">
                    <img
                      src={datalist.image}
                      className="img-fluid"
                      alt={datalist.title}
                    />
                    <div className="portfolio-links">
                      <a
                        href={datalist.image}
                        data-gall="portfolioGallery"
                        className="venobox"
                        title={datalist.title}
                      >
                        <i className="bx bx-search-alt"></i>
                      </a>
                      {(() => {
                        if (datalist.link !== "#") {
                          return (
                            <a
                              href={datalist.link}
                              title="More Details"
                              rel="noopener"
                            >
                              <i className="bx bx-link"></i>
                            </a>
                          );
                        }
                      })()}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
