import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { HashLink as Link } from 'react-router-hash-link'


export class Navbar extends Component {
    render() {
        return (
            <header className="header">
                <Helmet>
                    <title>Welcome</title>
                </Helmet>
                <nav className="navbar navbar-expand-lg fixed-top py-3">
                    <div className="container">
                        <Link to="#" className="navbar-brand  font-weight-bold">Diwash</Link>
                        <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler navbar-toggler-right"><i className="icofont-navigation-menu"></i></button>

                        <div id="navbarSupportedContent" className="collapse navbar-collapse">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active"><Link to="#" className="nav-link  font-weight-bold">Home <span className="sr-only">(current)</span></Link></li>
                                <li className="nav-item"><Link to="#about" className="nav-link  font-weight-bold">About</Link></li>
                                <li className="nav-item"><Link to="#skills" className="nav-link  font-weight-bold">Skills</Link></li>
                                <li className="nav-item"><Link to="#resume" className="nav-link  font-weight-bold">Resume</Link></li>
                                <li className="nav-item"><Link to="#portfolio" className="nav-link  font-weight-bold">Portfolio</Link></li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Navbar
