import React, { Component } from "react";
import { personalData } from "../database/data";

export class About extends Component {
  render() {
    return (
      <section id="about" className="about">
        <div className="container">
          <div className="section-title ">
            <h2>About Me</h2>
          </div>

          <div className="row">
            <div className="col-lg-4" data-aos="fade-right">
              <img
                src={personalData.img}
                className="img-fluid"
                title={personalData.name}
                alt="Diwash Bhandari"
              />
            </div>
            <div className="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
              <h3>Summary</h3>
              <p>
                <em>{personalData.description}</em>
              </p>
              <div className="row">
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="icofont-rounded-right"></i>{" "}
                      <strong>Email: </strong>
                      <a href={`mailto:${personalData.email}`} rel="noopener">
                        {personalData.email}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6">
                  <ul>
                    <li>
                      <i className="icofont-rounded-right"></i>{" "}
                      <strong>City: </strong> {personalData.city}
                    </li>
                  </ul>
                </div>
              </div>
              {/* <p>
                List of tools that I use in{" "}
                <b>Web Development & UI/UX Designing</b>
              </p>
              <ul className="list-inline text-center my-4">
                {personalData.tools.map((tool) => {
                  return (
                    <li
                      className="list-inline-item m-3 "
                      data-aos="zoom-in-up"
                      data-aos-delay="200"
                      key={tool.id}
                    >
                      <img
                        loading="lazy"
                        src={tool.image}
                        height="70"
                        alt={tool.title}
                        title={tool.title}
                      />
                    </li>
                  );
                })}
              </ul>
              <p>and many more ....</p> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
