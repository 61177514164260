export const PortfolioList = {
    // FilterData: [
    //     { id: 1, name: "App", filter: ".filter-app" },
    //     { id: 2, name: "Card", filter: ".filter-card" },
    //     { id: 3, name: "Web", filter: ".filter-web" },
    // ],
    DataList: [
        // { id: 1, title: "App", image: "/images/mobile/1.jpg", category: "filter-app" },
        // { id: 3, title: "App", image: "/images/mobile/3.jpg", category: "filter-app" },
        { id: 2, title: "Web", image: "/images/web/nepal_web.png", category: "filter-web", link: 'https://nepal-tourism.netlify.app/' },
        { id: 4, title: "Web", image: "/images/web/dashboard.png", category: "filter-web", link: 'http://merohospital.netlify.app/' },
        { id: 5, title: "Web", image: "/images/web/landingpage.png", category: "filter-web", link: 'https://diwash-bhandari.com.np' },
        { id: 10, title: "Web", image: "/images/web/nepali_thami.png", category: "filter-web", link: 'https://nepali-thami.netlify.app/' },
        // { id: 6, title: "App", image: "/images/mobile/2.jpg", category: "filter-app" },
        // { id: 7, title: "Card", image: "/images/card/corona.jpg", category: "filter-card" },
        // { id: 8, title: "Card", image: "/images/card/insta.jpg", category: "filter-card" },
        // { id: 9, title: "Card", image: "/images/card/dlogo.jpg", category: "filter-card" },
    ],
};