import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import React from "react";
import Footer from "./component/code/Footer";
import PageNotFound from "./component/code/PageNotFound";
import Preloader from "./component/code/Preloader";
import Header from "./component/code/Header";
import About from "./component/code/About";
import Skills from "./component/code/Skills";
import "./component/assets/css/style.css";
import Resume from "./component/code/Resume";
import Portfolio from "./component/code/Portfolio";
import Navbar from "./component/code/Navbar";
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Preloader />
              <Navbar />
              <Header />
              <About />
              <Skills />
              <Resume />
              <Portfolio />
              <Footer />
            </>
          }
        />
        {/* <Route path="*" element={<Navigate to="/404" />} /> */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Link to="#" className="back-to-top">
        <i className="icofont-simple-up"></i>
      </Link>
    </BrowserRouter>
    <SpeedInsights />
    <Analytics />
    </>
    
  );
}

export default App;
