import React, { Component } from 'react';
import'../assets/css/error.css';
import {Helmet} from 'react-helmet';
import {Link} from 'react-router-dom';
export class PageNotFound extends Component {
    render() {
        const refreshPage = ()=>{
            
            window.location.href = window.location.origin;
         }
        return (
            <div className="error">
                <Helmet>
        <title>404- Page Not Found</title>
      </Helmet>
            <div className="container-floud">
                <div className="col-xs-12 ground-color text-center">
                    <div className="errorPage">
                        <div className="clip"><div className="shadow"><span className="digit numberThree"> 4  </span></div></div>
                        <div className="clip"><div className="shadow"><span className="digit numberTwo"> 0 </span></div></div>
                        <div className="clip"><div className="shadow"><span className="digit numberOne"> 4  </span></div></div>
                    </div>
                    <h2>Sorry! Page not found</h2>
                    <Link to="/" className="btn btn-danger " style={{ color:"white" }} onClick={refreshPage} ><i className="bx bx-arrow-back"></i> Return Back</Link>
                </div> 
            </div>
        </div>
        )
    }
}

export default PageNotFound
