import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../assets/css/scrollmouse.css";
import $ from "jquery";
import { personalData, social_medias, CV } from "../database/data";

export class Header extends Component {
  render() {
    const scrolldown = () => {
      $("html, body").animate(
        {
          scrollTop: $("#about").offset().top,
        },
        100
      );
    };
    return (
      <section
        id="perosnal"
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <div className="perosnal-container text-center">
          <div>
            <h1
              className="script-font "
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Hi This is
            </h1>
            <h1 className="head-title text-warning ">{personalData.name}</h1>
            <p
              className="script-font"
              data-aos="fade-up-right"
              data-aos-delay="350"
            >
              {personalData.headline}
            </p>
          </div>

          <ul className="social-icon " data-aos="zoom-in-down">
            {social_medias.socialists.map((socialist) => {
              return (
                <li key={socialist.id}>
                  <a
                    className={socialist.name}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={socialist.title}
                    href={socialist.link}
                    id={socialist.id}
                  >
                    <i className={socialist.icon}></i>
                  </a>
                </li>
              );
            })}
            <li></li>
          </ul>
          <Link
            to={CV.link}
            // to={{
            //   pathname: "/#resume",
            // }}
            target="_blank"
            download={CV.name}
            className="btn btn-success btn-block-inline"
            data-aos="fade-down-left"
            data-aos-delay="700"
            title="Resume"
          >
            <i className={CV.icon}></i> {CV.name}
          </Link>
          <div className="scroll-down-animation" style={{ paddingTop: "2rem" }}>
            <Link to="#" className="scrolldown" title="Scroll Down">
              <span className="mouse" onClick={scrolldown}>
                <span className="move"></span>
              </span>
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

export default Header;
