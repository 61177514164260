import React, { Component } from 'react';
import { EducationList, ExperienceList } from '../database/data'

export class Resume extends Component {
  render() {
    return (
      <section id="resume" className="resume">
        <div className="container">

          <div className="section-title">
            <h2>Resume</h2>
            <p>Here I have listed My  <b style={{ letterSpacing: 1 }}>[Summary,Qualifications,Work Experience]</b> </p>
          </div>

          <div className="row">
            <div className="col-lg-6" data-aos="fade-up">
              <h3 className="resume-title">Education</h3>
              {EducationList.map((education) => {
                return (
                  <div className="resume-item" key={education.id}>
                    <h4>{education.level} </h4>
                    <h5>{education.start_date + "-" + education.end_date}</h5>
                    <p><em className="font-weight-bold">{education.name}</em> <br /> <em>{education.location}</em></p>
                  </div>
                );
              })}

            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
              <h3 className="resume-title">Experience</h3>
              {ExperienceList.map((experience) => {
                return (
                  <div className="resume-item" key={experience.id}>
                    <h4>{experience.level}</h4>
                    <h5>{experience.join_date + " / " + experience.end_date}</h5>
                    <p><em className="font-weight-bold">{experience.office}</em><br /><em>{experience.location}</em></p>
                    <ul>
                      {experience.lists.map((descriptionlist) => {
                        return (
                          <li key={descriptionlist.id}>{descriptionlist.description}</li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}

            </div>
          </div>

        </div>
      </section >
    )
  }
}

export default Resume
